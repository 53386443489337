import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import win from '../assets/win.png';
import wand from '../assets/magic-wand.png';
import stake from '../assets/stake.png';
import artFund from '../assets/artist.png';
import brand from '../assets/brand.png';
import FootPrint from './footePrint';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}


class Utility extends Component {




    render() {
        return (

            <div class="boxWrap2Rm">

                <div class="storyH">ROADMAP</div>

                <div class="about">

                    <div class="storyConRm">
                        <div class="winDiv">
                            <div class="winCon">SOP ART RAFFLES</div>
                            <div>
                                <img src={win} />
                            </div>
                        </div>
                        <p class="storyCon2Rm">Holders will be able to participate in bi-weekly art raffles where if selected will be able to mint special pepe artworks for free that are exclusively made for SOP. Supply will vary per raffle based on the complexity of the artwork.</p>
                        <p class="storyCon2Rm">For example, special 1/1 might be raffled for all holders to participate in but only one holder will win and be able to mint and own this exclusive artwork. On the other hand some raffles might allow all participants to mint.</p>
                        <p class="storyCon2Rm">Winners of these raffles will be able to sell the artworks on secondary market. Our goal with this is to reward our holders and fill their 3d art galleries with some of the most creative art in the space.</p>
                    </div>

                    <div class="storyConRm2" >
                        <div class="winDiv">
                            <div class="winCon2">FOR THE ARTISTS</div>
                            <div>
                                <img src={wand} />
                            </div>
                        </div>
                        <p class="storyCon2Rm">SOP art raffles will also benefit pepe artists. Submissions will be open for all artists to submit their own artworks. The artworks/ artist’s that are selected will be compensated for their work beforehand and also receive 50% of all secondary fees to truly reward the artists too.</p>

                    </div>

                    <div class="storyConRm3">
                        <div class="winDiv">
                            <div class="winCon3">Art Fund</div>
                            <div>
                                <img src={artFund} />
                            </div>
                        </div>
                        <p class="storyCon2Rm">SOP will use funds from mint to support frogfam artists and 1/1 artists, we truly believe in supporting artists and collecting the most creative works out there. We will display these artworks in oncyber.io and feature them in future clothing drops and bring them to life!</p>

                    </div>

                    <div class="storyConRm4">
                        <div class="winDiv">
                            <div class="winCon4">Clothing Drops</div>
                            <div>
                                <img src={brand} />
                            </div>
                        </div>
                        <p class="storyCon2Rm">We don’t only want to be seen as an NFT collection we want to be seen as a brand also and believe we have all the resources to do so. Fashion is very important and we will bring our holders high quality, good looking clothing you can wear out anywhere.</p>
                        <p class="storyCon2Rm">A lot of the current NFT “merch” is tacky and scattered with branding and we want to change this! Clothing will be carefully picked and designed with pepe in mind. Some clothing will even feature some of the artworks we collect from the art fund.</p>
                        <p class="storyCon2Rm">We don’t plan on stopping at just clothing either.
                            We have seen both cheap and overpriced NFT merch and don’t want to fall into either category, although merch won’t be free we want to have balanced pricing for being a holder. Profits from clothing drops will also help generate new funds for the project to keep things flowing.</p>
                    </div>

                    <div class="storyConRm5">
                        <div class="winDiv">
                            <div class="winCon5">Staking</div>
                            <div>
                                <img src={stake} />
                            </div>
                        </div>
                        <p class="storyCon2Rm">Staking will be available at launch and staked Pepe’s will count as 3 entries for art raffles compared to 1 raffle entry being unstaked. Stakers will also be eligible to redeem free irl rewards based on the time they have been staked. If a pepe is unstaked the time will be paused and if transferred/ sold the time staked will carry over to the new owner who can restake and claim future rewards</p>
                        <p>(1 staked SOP = 3 Raffle Tickets + IRL rewards</p>
                        <p>1 Unstaked SOP = 1 Raffle Ticket & NO REWARDS)</p>
                    </div>

                </div>

                <FootPrint />
            </div>
        )
    }
}

export default Utility;

