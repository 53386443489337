import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import Carousel from '../components/carousel';
import FootPrint from './footePrint';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}


class Story extends Component {




    render() {
        return (

            <div class="boxWrap2" >

                <div class="storyH">ABOUT</div>

                <div class="about">
                    <div class="storyCon">
                        <p class="storyCon2">Society of Pepe (SOP) is a collection of 3333 handcrafted Pepe’s. We have worked with 5 different artists from the frogfam community to put together a collection that feels unique, special, and diverse. With tons of variety and over 250 traits, there’s truly something for everyone.</p>
                        <p class="storyCon2">Our main goal is to support and encourage 1/1 artists and reward our holders with unique pepe artwork and experience’s. We will continue to expand the brand and bring more for our holders continuously throughout the future.</p>
                    </div>

                </div>
                
                <Carousel />
                <FootPrint />

            </div>
        )
    }
}

export default Story;

