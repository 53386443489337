import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import bokiizy from '../assets/Bokiizy.jpg';
import frogButBird from '../assets/Frogbutbird.jpg';
import burak from '../assets/Burak.jpg';
import baanbe from '../assets/baanbe_studio.jpg';
import twitter from '../assets/twitter.png';
import FootPrint from './footePrint';

let art = 'https://sopassets.pages.dev/sop/art.jfif';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

const mem1 = () => {
    window.open("https://twitter.com/bokiizy1");
  }
  
  
  const mem2 = () => {
    window.open("https://twitter.com/frogbutbird");
  }
  
  
  const mem3 = () => {
    window.open("https://twitter.com/b_artwalker");
  }
  
  const mem4 = () => {
    window.open("https://twitter.com/BaanbeS");
  }

class Artist extends Component {




    render() {
        return (

            <div class="boxWrap2Art">

                <div class="storyH">ARTISTS</div>

                <div class="teamSection">
          <div class="team1">
            <div class="manDiv"><img class="man" src={bokiizy} /></div>
            <div class="tAndName">
              <div class="memName">Bokiizy</div>
              <div class="tPicDiv"><img class="tPic" src={twitter} onClick={mem1} /></div>
            </div>
           </div>

          <div class="team1">
            <div class="manDiv"><img class="man" src={frogButBird} /></div>
            <div class="tAndName">
              <div class="memName">Frogbutbird</div>
              <div class="tPicDiv"><img class="tPic" src={twitter} onClick={mem2} /></div>
            </div>
          </div>

          <div class="team1">
            <div class="manDiv"><img class="man" src={burak} /></div>
            <div class="tAndName">
              <div class="memName">Burak</div>
              <div class="tPicDiv"><img class="tPic" src={twitter} onClick={mem3} /></div>
            </div>
          </div>

          <div class="team1">
            <div class="manDiv"><img class="man" src={baanbe} /></div>
            <div class="tAndName">
              <div class="memName">baanbe_studio</div>
              <div class="tPicDiv"><img class="tPic" src={twitter} onClick={mem4} /></div>

            </div>
          </div>

        </div>

                <div class="about">
                    <img class="art" src={art} />

                </div>

                <FootPrint />

            </div>
        )
    }
}

export default Artist;

