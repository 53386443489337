import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import logo from '../assets/favicon.png';
import '../App.css';
import frogFoot from '../assets/frogFoot.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}


class FootPrint extends Component {




    render() {
        return (

            <div >
                <div class="container">

                    <div class="footprint">

                        <img class="foot" src={frogFoot} alt="footprintLeft" />
                    </div>

                    <div class="footprint right">

                        <img class="foot" src={frogFoot} alt="footprintRight" />
                    </div>
                </div>

            </div>
        )
    }
}

export default FootPrint;

