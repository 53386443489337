import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import logo from '../assets/favicon.png';
import twitter from '../assets/twitter (2).png';
import discord from '../assets/discord.png';
import opensea from '../assets/opensea.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

const tweet = () => {
	window.open("https://twitter.com/societyofpepe");
}

const discrd = () => {
	window.open("https://discord.gg/wSPwT3f5E7");
}

const os = () => {
	window.open("https://discord.gg/wSPwT3f5E7");
}

class Footer extends Component {

    render() {
        return (

            <div class="boxWrap2Footer">


                <div class="footer">
                <div><img class="logoF" src={logo}/></div>
                <div class="footerIcons">
                    <img src={twitter} onClick={tweet}/>
                    <img src={discord} onClick={discrd}/>
                    <img src={opensea} onClick={os}/>
                </div>
                <div class="copyright">Copyright © 2022 SOP. All Rights Reserved</div>
                </div>

            </div>
        )
    }
}

export default Footer;

