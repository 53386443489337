import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import twitter from '../assets/twitter.png';
import FootPrint from './footePrint';

let teamMember = 'https://sopassets.pages.dev/sop/swusha.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

const swushas = () => {
    window.open("https://twitter.com/SwushaNFT");
  }

class Team extends Component {




    render() {
        return (

            <div class="boxWrap2Team">
                <div class="storyH">TEAM</div>

                <div class="teamCon">

                    <img src={teamMember} />
                    </div>
                    <div class="memberDetails">
                        <p></p>
                        <p>
                            <div class="memberName1">Founder</div>
                            <div class="memberName">Swusha</div>
                            <div class="twitterTeamDiv"><img class="twitterTeam" onClick={swushas} src={twitter}/></div>
                        </p>
                    
                </div>

                <FootPrint />

            </div>
        )
    }
}

export default Team;

