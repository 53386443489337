import React, { Component } from 'react';
import '../carousel.css';
let d1 = 'https://sopassets.pages.dev/sop/1.jpg';
let d2 = 'https://sopassets.pages.dev/sop/2.jpg';
let d3 = 'https://sopassets.pages.dev/sop/3.jpg';
let d4 = 'https://sopassets.pages.dev/sop/4.jpg';
let d5 = 'https://sopassets.pages.dev/sop/5.jpeg';
let d6 = 'https://sopassets.pages.dev/sop/6.jpeg';
let d7 = 'https://sopassets.pages.dev/sop/7.jpg';
let d8 = 'https://sopassets.pages.dev/sop/8.jpeg';
let d9 = 'https://sopassets.pages.dev/sop/9.jpg';
let d10 = 'https://sopassets.pages.dev/sop/10.jpg';
let d11 = 'https://sopassets.pages.dev/sop/11.jpg';
let d12 = 'https://sopassets.pages.dev/sop/12.jpg';
let d13 = 'https://sopassets.pages.dev/sop/13.jpeg';
let d14 = 'https://sopassets.pages.dev/sop/14.jpeg';
let d15 = 'https://sopassets.pages.dev/sop/15.jpg';
let d16 = 'https://sopassets.pages.dev/sop/16.jpg';
let d17 = 'https://sopassets.pages.dev/sop/17.jpg';
let d18 = 'https://sopassets.pages.dev/sop/18.jpg';
let d19 = 'https://sopassets.pages.dev/sop/19.jpg';
let d20 = 'https://sopassets.pages.dev/sop/20.jpg';
let d21 = 'https://sopassets.pages.dev/sop/21.jpg';
let d22 = 'https://sopassets.pages.dev/sop/22.jpg';
let d23 = 'https://sopassets.pages.dev/sop/23.jpg';
let d24 = 'https://sopassets.pages.dev/sop/24.jpg';
let d25 = 'https://sopassets.pages.dev/sop/25.jpg';
let d26 = 'https://sopassets.pages.dev/sop/26.jpg';
let d27 = 'https://sopassets.pages.dev/sop/27.jpg';
let d28 = 'https://sopassets.pages.dev/sop/28.jpg';

class Carousel extends Component { 

    render() {
        return (

            <div class="boxWrapC">

                <div class="slider">
                    <div class="slide-track2">
                        <div class="slide">
                            <img class="carouselIMG" src={d1} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d2} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d3} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d4} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d5} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d6} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d7} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d8} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d9} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d10} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d11} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d12} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d13} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d14} alt="d5" />
                        </div>
                    </div>
                </div>

                <div class="slider2">
                    <div class="slide-track3">
                        <div class="slide">
                            <img class="carouselIMG" src={d15} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d16} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d17} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d18} alt="d5" />
                        </div>
                       
                        <div class="slide">
                            <img class="carouselIMG" src={d19} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d20} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d21} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d22} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d23} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d24} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d25} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d26} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d27} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d28} alt="d5" />
                        </div>
                
                    </div>
                </div>

            </div>
        );
    }
}

export default Carousel;        